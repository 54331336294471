import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Helmet from "react-helmet";
import Layout from 'components/Layout';
import BlogPost from 'components/pages/BlogPost';
import { Image } from 'components/pages/BlogPost/styled';
import { ImgGatsbyBackground } from 'components/wrappers';
import ShipLiquidImageSource from 'images/how-to-ship-liquids.jpg';
import ShipLiquidThumbImageSource from 'images/how-to-ship-liquids.jpg';
import styled, { css } from 'styled-components';
import { blogPosts } from 'common/blogPostsFeatured';
import BlogBaseCard from 'components/blog/BaseCard';
import OptinBlog from 'components/pages/OptinBlog';
import BlogSidebar from 'components/blog/BlogSidebar';
import {PostsWrapper} from 'components/pages/Blog/styled';
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from 'react-share';
import FacebookDefaultIcon from 'images/social-share-icons/facebook-white.png';
import TwitterDefaultIcon from 'images/social-share-icons/twitter-white.png';
import LinkedinDefaultIcon from 'images/social-share-icons/linkedin-white.png';
import BlogOptin from 'components/blog/BlogOptin';
import {Clear, BlogRow, ShareButton, ReadNow, DateText, TitleText, BottomShare, SectionFull, OptinSection} from 'components/blog/SingleBlogStyle';

interface OwnProps {
  location: {
    href: string;
  };
}

const BlogArticle = ({ location }: OwnProps) => {
  const data = useStaticQuery(graphql`
    query ImgBlog6Query {
      file(relativePath: { eq: "how-to-ship-liquid-banner.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1400) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const BackgroundImage = () => <ImgGatsbyBackground fluid={data.file.childImageSharp.fluid} alt="water drops"/>;
  const { href } = location;
  const intl = useIntl()
  return (
    <Layout>
      <Helmet 
        // title="How to Ship Liquids : Tips for You | Shipkoo"
        title={intl.formatMessage({ id: "blog-post-meta-title-how-to-ship-liquids" })}
        htmlAttributes={{
          lang: 'en',
        }}
         meta={
          [
            {
              name: `description`,
              content: "Many couriers are not accepting those products because most liquids are dangerous. Here are some tips to ship them succesfully.",
            },
            {
              property: `og:title`,
              // content: "How to Ship Liquids : Tips for You | Shipkoo",
              content: intl.formatMessage({ id: "blog-post-meta-title-how-to-ship-liquids" }),
            },
            {
              property: `og:description`,
              content: "Many couriers are not accepting those products because most liquids are dangerous. Here are some tips to ship them succesfully.",
            },
            {
              property: `og:type`,
              content: `website`,
            },
            {
              property: `og:image`,
              content: ShipLiquidImageSource,
            },
            {
              name: `twitter:card`,
              content: `summary`,
            },
          ] as JSX.IntrinsicElements['meta'][]
        }
      />
      <BlogPost
        location={location}
        id="how-to-ship-liquids-internationally"
        title="How to Ship Liquids Internationally"
        date="2020-03-27"
        author="Joe Zhang"
        Background={BackgroundImage}
      >
        <BlogRow>
          <BlogSidebar/>
          <div className="column-half last">
            <p>
              To ship liquids isn’t an easy task. Many couriers are not accepting those products because they are dangerous. And if they do, you may need to meet all their requirements. Shipping liquids across borders is even more complicated because each destination country has  its own set of regulations. Many countries have strict rules for flammable or alcoholic products and a special license may be required.
            </p>
            
            <p>
              The most important thing is to know how to carefully pack your shipments. They should be protected to avoid leakage or damage.
            </p>

            <p>
              Here are some tips for you on how to ship liquids safely:
            </p>

            <p>
              <span>
                <strong>
                  1. Identify
                </strong>  
              </span>
            </p>

            <p>
              Always make sure to identify the type of liquid. There are some liquids that are considered dangerous. It is better to have an understanding with the kind of product before shipping. Nail polish, gasoline, perfumes containing alcohol, alcoholic beverages, or poisons cannot be mailed internationally through ordinary services, a commercial carrier is  required.
            </p>

            <p>
              <span>
                <strong>
                  2. Double seal
                </strong>  
              </span>
            </p>

            <p>
              Make sure that your product has a double seal. It is the most possible way to prevent your product from leakage. Double seal can keep the bottle or product secure. Double check the product if it won't take off during the shipping period.
            </p>
              
            <p>
              <span>
                <strong>
                  3. Waterproof bag
                </strong>  
              </span>
            </p>

            <p>
              Make sure that the bottle containing the liquid is inside a waterproof bag. You need to find a large waterproof bag, just in case it will stick out. Make sure that bottle is completely sealed inside a waterproof bag.
            </p>
            
            <p>
              <span>
                <strong>
                  4. Cardboard box
                </strong>  
              </span>
            </p>

            <p>
              Prepare a cardboard box. Place the product inside a cardboard box and make sure to fill in any space with packing peanuts or a bubble wrap. Just to make sure you have extra protection for your product.
            </p>

            <p>
              <span>
                <strong>
                  5. Mark your box
                </strong>  
              </span>
            </p>
              
            <p>
              Make sure to write the words "FRAGILE" or "LIQUID" on all sides of your package. This note will let the couriers or handlers know that they must be handled with care.
            </p>
            <Image src={ShipLiquidImageSource} alt="Pile of flowers beside a bottle" />
            <BottomShare>
              <div className="column-half">
                <p>Share the article</p>
              </div>
              <div className="column-half last">
                <FacebookShareButton url={href}>
                  <ShareButton><img src={FacebookDefaultIcon} alt="Facebook Icon" /> <span>Share</span></ShareButton>
                </FacebookShareButton>
                <TwitterShareButton url={href}>
                  <ShareButton><img src={TwitterDefaultIcon} alt="Twitter Icon" /> <span>Tweet</span></ShareButton>
                </TwitterShareButton>
                <LinkedinShareButton url={href}>
                  <ShareButton><img src={LinkedinDefaultIcon} alt="Linked Icon" /> <span>Share</span></ShareButton>
                </LinkedinShareButton>
              </div>
              <Clear></Clear>
            </BottomShare>
          </div>
          <Clear></Clear>
        </BlogRow>
      </BlogPost>
      <SectionFull>
        <BlogRow>
          <h2>More From Shipkoo</h2>
          <PostsWrapper>
            {blogPosts.map(post => (
              <BlogBaseCard key={`${post.title}-${post.id}`} post={post} />
            ))}
          </PostsWrapper>
        </BlogRow>
      </SectionFull>
      <BlogOptin />
    </Layout>
  );
};

export default BlogArticle;

